// Home.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import { doc, setDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { db } from './Firebase';
import TopicHeader from './TopicHeader';
import ContentInput from './ContentInput';
import TermsEditor from './TermsEditor';

const Home = () => {
 const [currentStep, setCurrentStep] = useState(1);
 const [keyTerms, setKeyTerms] = useState([]);
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState(null);
 const navigate = useNavigate();
 const { currentUser } = useContext(AuthContext);

 useEffect(() => {
   if (currentUser) {
     createUserDoc(currentUser);
   }
 }, [currentUser]);

 const createUserDoc = async (user) => {
   const userRef = doc(db, 'users', user.uid);
   await setDoc(userRef, {
     subscriptionStatus: 'free',
     subscriptionId: null,
     subscriptionEndsAt: null,
     stripeCustomerId: null,
     lastUpdated: Timestamp.now()
   }, { merge: true });
 };

 const handleDeleteTopic = async (topicId) => {
   if (window.confirm("Are you sure you want to delete this topic?")) {
     try {
       await deleteDoc(doc(db, 'topics', topicId));
       window.location.reload();
     } catch (error) {
       console.error('Error deleting topic:', error);
       setError('Failed to delete topic');
     }
   }
 };

 return (
   <div className="min-h-screen bg-gradient-to-br from-sand to-sand/60">
     <div className="relative max-w-7xl mx-auto px-4 py-8">
       <div className="absolute top-0 left-0 w-64 h-64 bg-gradient-to-br from-olive-light/10 to-transparent rounded-full blur-3xl -z-10" />
       <div className="absolute bottom-0 right-0 w-96 h-96 bg-gradient-to-tl from-copper-light/10 to-transparent rounded-full blur-3xl -z-10" />
       <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full bg-white/5 backdrop-blur-[100px] -z-20" />

       <TopicHeader 
         currentUser={currentUser}
         currentStep={currentStep}
         onDeleteTopic={handleDeleteTopic}
       />

       {currentStep === 1 && (
         <ContentInput
           loading={loading}
           setLoading={setLoading}
           setKeyTerms={setKeyTerms}
           setCurrentStep={setCurrentStep}
           setError={setError}
           currentUser={currentUser}
         />
       )}

       {currentStep === 2 && keyTerms.length > 0 && (
         <TermsEditor
           keyTerms={keyTerms}
           setKeyTerms={setKeyTerms}
           loading={loading}
           setLoading={setLoading}
           error={error}
           setError={setError}
           currentUser={currentUser}
           setCurrentStep={setCurrentStep}
         />
       )}

       {error && (
         <div className="mt-6 p-4 bg-red-100/80 backdrop-blur-sm border border-red-400 text-red-700 rounded-xl shadow-lg">
           {error}
         </div>
       )}
     </div>
   </div>
 );
};

export default Home;