// TopicHeader.js
import React from 'react';
import TopicsList from './TopicsList';

const TopicHeader = ({ currentUser, currentStep, onDeleteTopic }) => {
  return (
    <>
      {!currentUser && currentStep === 1 && (
        <div className="relative mb-8 text-center">
          <div className="absolute inset-0 bg-gradient-to-r from-olive-dark/2 to-copper-dark/2 rounded-3xl blur-lg" />
          <div className="relative backdrop-blur-sm bg-white p-4 sm:p-8 rounded-3xl border border-white/10">
            <h1 className="text-2xl sm:text-4xl font-bold mb-4 text-olive-dark/80">
              Transform Your Notes
            </h1>
            <p className="text-lg sm:text-xl text-olive-dark/60 max-w-2xl mx-auto leading-relaxed">
              Upload your study material and watch it transform into an interactive learning experience
            </p>
            <div className="mt-8 sm:mt-12">
              <p className="text-sm font-medium uppercase tracking-wider text-olive-dark/60 mb-4 sm:mb-6">
                Trusted by students at
              </p>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4 sm:gap-6 md:gap-8 items-center justify-items-center px-4">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1aPUc0QKS6_lhrn1PzCyMNwC3yZypQC8e4w&s"
                  alt="UConn"
                  className="h-6 sm:h-8 w-auto max-w-[120px] opacity-40 hover:opacity-90 transition-opacity grayscale hover:grayscale-0"
                />
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTIibQIrBy_q6Bopg_R8ZGmc6zF_PVwHgCcA&s"
                  alt="UC Berkeley" 
                  className="h-6 sm:h-8 w-auto max-w-[120px] opacity-40 hover:opacity-90 transition-opacity grayscale hover:grayscale-0"
                />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/0/0c/MIT_logo.svg"
                  alt="MIT"
                  className="h-6 sm:h-8 w-auto max-w-[120px] opacity-40 hover:opacity-90 transition-opacity grayscale hover:grayscale-0"
                />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/2/2f/University_of_Oxford.svg"
                  alt="Oxford"
                  className="h-6 sm:h-8 w-auto max-w-[120px] opacity-40 hover:opacity-90 transition-opacity grayscale hover:grayscale-0"
                />
                <img
                  src="https://banner2.cleanpng.com/20180413/kpe/avfca86bk.webp"
                  alt="Yale"
                  className="h-6 sm:h-8 w-auto max-w-[120px] opacity-40 hover:opacity-90 transition-opacity grayscale hover:grayscale-0"
                />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Nyu_short_color.svg/2560px-Nyu_short_color.svg.png"
                  alt="NYU"
                  className="h-6 sm:h-8 w-auto max-w-[120px] opacity-40 hover:opacity-90 transition-opacity grayscale hover:grayscale-0"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentUser && currentStep === 1 && (
        <div className="relative mb-12">
          <div className="absolute inset-0 bg-gradient-to-r from-olive-dark/5 to-copper-dark/5 rounded-3xl blur-xl" />
          <div className="relative backdrop-blur-lg bg-white/20 p-8 rounded-3xl border border-white/20 shadow-2xl">
            <h3 className="text-3xl font-bold mb-6 bg-gradient-to-r from-olive-dark to-copper-dark bg-clip-text text-transparent">
              Recent Topics
            </h3>
            <TopicsList compact={true} onDeleteTopic={onDeleteTopic} />
          </div>
        </div>
      )}
    </>
  );
};

export default TopicHeader;