// TermsEditor.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, doc, getDoc, setDoc, updateDoc, query, getDocs, where } from 'firebase/firestore';
import { db } from './Firebase';
import { SUBSCRIPTION_TIERS } from './subscriptionUtils';

const TermsEditor = ({ keyTerms, setKeyTerms, loading, setLoading, error, setError, currentUser, setCurrentStep }) => {
  const [editingTermIndex, setEditingTermIndex] = useState(null);
  const [topicName, setTopicName] = useState('');
  const [editForm, setEditForm] = useState({
    term: '',
    definition: '',
    importance: '',
    example: '',
  });
  
  const navigate = useNavigate();

  const handleEditTerm = (index) => {
    const term = keyTerms[index];
    setEditForm({
      term: term.term,
      definition: term.definition,
      importance: term.importance,
      example: term.example || '',
    });
    setEditingTermIndex(index);
  };

  const handleSaveEdit = () => {
    if (!editForm.term.trim() || !editForm.definition.trim() || !editForm.importance.trim()) {
      setError('Term, definition, and importance are required.');
      return;
    }

    const updatedTerms = [...keyTerms];
    const newTerm = {
      term: editForm.term.trim(),
      definition: editForm.definition.trim(),
      importance: editForm.importance.trim(),
      example: editForm.example.trim(),
    };

    if (editingTermIndex === -1) {
      updatedTerms.push(newTerm);
    } else {
      updatedTerms[editingTermIndex] = newTerm;
    }

    setKeyTerms(updatedTerms);
    setEditingTermIndex(null);
    setEditForm({ term: '', definition: '', importance: '', example: '' });
  };

  const saveTopic = async () => {
    if (!topicName.trim()) {
      setError('Please enter a topic name.');
      return;
    }

    if (!currentUser) {
      setError('You must be logged in to save a topic.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // First verify user document exists
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        await setDoc(userRef, {
          email: currentUser.email,
          subscriptionStatus: SUBSCRIPTION_TIERS.FREE,
          subscriptionId: null,
          subscriptionEndsAt: null,
          stripeCustomerId: null,
          createdAt: serverTimestamp(),
          lastUpdated: serverTimestamp()
        });
      }

      // Get user's topics count
      const topicsRef = collection(db, 'topics');
      const q = query(topicsRef, where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);
      const topicCount = querySnapshot.size;

      // Check subscription status and topic limit
      const userData = userDoc.exists() ? userDoc.data() : { subscriptionStatus: SUBSCRIPTION_TIERS.FREE };
      const isFreeTier = userData.subscriptionStatus === SUBSCRIPTION_TIERS.FREE;

      if (isFreeTier && topicCount >= 2) {
        setError('You have reached the free tier limit. Please upgrade to create more topics.');
        navigate('/upgrade', { state: { reason: 'topicLimit' } });
        return;
      }

      // Create the topic document
      const docRef = await addDoc(collection(db, 'topics'), {
        name: topicName.trim(),
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp(),
        keyTerms: keyTerms.map(term => ({
          term: term.term,
          definition: term.definition,
          importance: term.importance || '',
          example: term.example || ''
        })),
        userId: currentUser.uid
      });

      // Update user's lastUpdated timestamp
      await updateDoc(userRef, {
        lastUpdated: serverTimestamp()
      });

      setTopicName('');
      setCurrentStep(1);
      navigate(`/topics/${docRef.id}`);
    } catch (error) {
      console.error('Error saving topic:', error);
      setError('Error saving topic. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-8">
      {/* Topic name input */}
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-r from-olive-dark/5 to-copper-dark/5 rounded-3xl blur-xl" />
        <div className="relative backdrop-blur-lg bg-white/20 p-8 rounded-3xl border border-white/20 shadow-2xl">
          <div className="flex items-center gap-6">
            <input
              type="text"
              value={topicName}
              onChange={(e) => setTopicName(e.target.value)}
              placeholder="Enter topic name"
              className="flex-1 px-4 py-2 rounded-lg border border-olive-light text-olive-dark"
              disabled={loading}
            />
            <button
              onClick={saveTopic}
              disabled={loading}
              className="py-4 px-8 bg-gradient-to-r from-olive-dark to-copper-dark rounded-xl text-white">
              Save Topic
            </button>
          </div>
        </div>
      </div>

      {/* Terms list */}
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-r from-olive-dark/5 to-copper-dark/5 rounded-3xl blur-xl" />
        <div className="relative backdrop-blur-lg bg-white/20 p-8 rounded-3xl border border-white/20 shadow-2xl">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-olive-dark to-copper-dark bg-clip-text text-transparent">
              Review and Edit Terms
            </h2>
            <button
              onClick={() => {
                setEditForm({ term: '', definition: '', importance: '', example: '' });
                setEditingTermIndex(-1);
              }}
              className="py-3 px-6 bg-gradient-to-r from-olive-dark to-copper-dark rounded-xl text-white">
              Add Term
            </button>
          </div>

          {/* Terms list */}
          <div className="space-y-6">
            {keyTerms.map((term, index) => (
              <div key={index} className="relative group transition-all duration-300 hover:scale-[1.01]">
                <div className="absolute inset-0 bg-gradient-to-r from-olive-light/5 to-copper-light/5 rounded-xl blur-lg opacity-0 group-hover:opacity-100" />
                <div className="relative backdrop-blur-sm bg-white/40 rounded-xl p-8 border border-white/30">
                  <div className="flex justify-between items-start mb-6">
                    <h3 className="text-2xl font-semibold bg-gradient-to-r from-olive-dark to-copper-dark bg-clip-text text-transparent">
                      {term.term}
                    </h3>
                    <div className="flex space-x-3">
                      <button onClick={() => handleEditTerm(index)} className="p-2 text-olive-light hover:text-olive-dark transition-all duration-300 hover:scale-110">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                      </button>
                      <button onClick={() => {
                        const updatedTerms = [...keyTerms];
                        updatedTerms.splice(index, 1);
                        setKeyTerms(updatedTerms);
                      }} className="p-2 text-red-500 hover:text-red-700 transition-all duration-300 hover:scale-110">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="bg-white/30 backdrop-blur-sm rounded-lg p-4 border border-white/20">
                      <label className="block text-sm font-medium bg-gradient-to-r from-olive-dark to-copper-dark bg-clip-text text-transparent mb-2">Definition</label>
                      <p className="text-olive-dark/80">{term.definition}</p>
                    </div>

                    <div className="bg-white/30 backdrop-blur-sm rounded-lg p-4 border border-white/20">
                      <label className="block text-sm font-medium bg-gradient-to-r from-olive-dark to-copper-dark bg-clip-text text-transparent mb-2">Importance</label>
                      <p className="text-olive-dark/80">{term.importance}</p>
                    </div>

                    {term.example && (
                      <div className="bg-white/30 backdrop-blur-sm rounded-lg p-4 border border-white/20">
                        <label className="block text-sm font-medium bg-gradient-to-r from-olive-dark to-copper-dark bg-clip-text text-transparent mb-2">Example</label>
                        <p className="text-olive-dark/80">{term.example}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Edit modal */}
      {editingTermIndex !== null && (
        <div className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center p-4 z-50">
          <div className="relative w-full max-w-2xl">
            <div className="absolute inset-0 bg-gradient-to-r from-olive-dark/5 to-copper-dark/5 rounded-3xl blur-xl" />
            <div className="relative backdrop-blur-xl bg-white/90 rounded-3xl border border-white/30 shadow-2xl p-8">
              <h3 className="text-3xl font-bold bg-gradient-to-r from-olive-dark to-copper-dark bg-clip-text text-transparent mb-8">
                {editingTermIndex === -1 ? 'Add New Term' : 'Edit Term'}
              </h3>
              
              <div className="space-y-6">
                <input
                  type="text"
                  value={editForm.term}
                  onChange={(e) => setEditForm({ ...editForm, term: e.target.value })}
                  placeholder="Enter term"
                  className="w-full p-4 bg-white/40 backdrop-blur-sm rounded-xl border border-white/30 focus:ring-2 focus:ring-copper-light/30"
                />

                <textarea
                  value={editForm.definition}
                  onChange={(e) => setEditForm({ ...editForm, definition: e.target.value })}
                  placeholder="Enter definition"
                  rows="3"
                  className="w-full p-4 bg-white/40 backdrop-blur-sm rounded-xl border border-white/30 focus:ring-2 focus:ring-copper-light/30 resize-none"
                />

                <textarea
                  value={editForm.importance}
                  onChange={(e) => setEditForm({ ...editForm, importance: e.target.value })}
                  placeholder="Explain importance"
                  rows="3"
                  className="w-full p-4 bg-white/40 backdrop-blur-sm rounded-xl border border-white/30 focus:ring-2 focus:ring-copper-light/30 resize-none"
                />

                <textarea
                  value={editForm.example}
                  onChange={(e) => setEditForm({ ...editForm, example: e.target.value })}
                  placeholder="Add an example (optional)"
                  rows="3"
                  className="w-full p-4 bg-white/40 backdrop-blur-sm rounded-xl border border-white/30 focus:ring-2 focus:ring-copper-light/30 resize-none"
                />
              </div>

              <div className="mt-8 flex space-x-4">
                <button
                  onClick={handleSaveEdit}
                  className="flex-1 py-3 px-6 bg-gradient-to-r from-copper-dark to-copper-light text-white rounded-xl shadow-lg hover:shadow-xl hover:scale-[1.02]"
                >
                  Save
                </button>
                <button
                  onClick={() => setEditingTermIndex(null)}
                  className="flex-1 py-3 px-6 border border-gray-300 text-gray-700 rounded-xl hover:bg-gray-50 hover:scale-[1.02]"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsEditor;