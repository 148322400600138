// Navigation.js
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { auth } from './Firebase';
import { AuthContext } from './AuthProvider';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const generateBreadcrumbs = () => {
    const paths = location.pathname.split('/').filter(Boolean);
    if (paths.length === 0) return null;

    return (
      <div className="bg-white/5 backdrop-blur-sm">
        <div className="px-4 py-3">
          <div className="max-w-7xl mx-auto flex items-center space-x-3 text-sm">
            <Link to="/" className="text-sand hover:text-white transition-colors">
              Home
            </Link>
            {paths.map((path, index) => {
              const url = `/${paths.slice(0, index + 1).join('/')}`;
              const isLast = index === paths.length - 1;
              
              return (
                <React.Fragment key={url}>
                  <div className="w-[1px] h-4 bg-sand/20"></div>
                  {isLast ? (
                    <span className="text-white font-medium px-2 py-1 bg-sand/10 rounded">
                      {path}
                    </span>
                  ) : (
                    <Link
                      to={url}
                      className="text-sand hover:text-white transition-colors"
                    >
                      {path}
                    </Link>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="sticky top-0 z-50 backdrop-blur-md bg-opacity-80">
      <div className="bg-[#1a2f1a]/80 backdrop-blur-md">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex justify-between h-20">
            <div className="flex items-center">
              <Link to="/" className="flex items-center group">
                <div className="flex flex-col">
                  <span className="text-white text-3xl font-bold tracking-tight bg-gradient-to-r from-emerald-200 to-emerald-400 bg-clip-text text-transparent">
                    StudyHew
                  </span>
                  <div className="h-0.5 w-0 group-hover:w-full bg-gradient-to-r from-emerald-200 to-emerald-400 transition-all duration-300"></div>
                </div>
                <span className="text-xs text-zinc-400 ml-3 italic font-light tracking-wide">
                  your overachieving AI study buddy
                </span>
              </Link>
            </div>
            
            <div className="flex items-center">
              {currentUser ? (
                <nav className="hidden md:flex items-center space-x-6">
                  <Link
                    to="/"
                    className={`px-4 py-2 rounded text-sm font-medium transition-colors ${
                      location.pathname === '/'
                        ? 'bg-white/10 text-white'
                        : 'text-zinc-400 hover:text-white hover:bg-white/5'
                    }`}
                  >
                    Topics
                  </Link>
                  <Link
                    to="/new-topic"
                    className={`px-4 py-2 rounded text-sm font-medium transition-colors ${
                      location.pathname === '/new-topic'
                        ? 'bg-white/10 text-white'
                        : 'text-zinc-400 hover:text-white hover:bg-white/5'
                    }`}
                  >
                    Create New Topic
                  </Link>
                  <div className="relative" ref={userMenuRef}>
                    <button
                      onClick={() => setShowUserMenu(!showUserMenu)}
                      className="flex items-center px-4 py-2 rounded text-sm font-medium text-zinc-400 hover:text-white hover:bg-white/5 transition-colors"
                    >
                      <span className="mr-2">{currentUser.email}</span>
                      <svg
                        className={`h-4 w-4 transition-transform ${showUserMenu ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>

                    {showUserMenu && (
                      <div className="absolute right-0 mt-2 w-48 bg-[#1a2f1a]/80 backdrop-blur-md rounded border border-[#2a3f2a]">
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-3 text-sm text-zinc-400 hover:text-white hover:bg-white/5 transition-colors"
                        >
                          Sign out
                        </button>
                      </div>
                    )}
                  </div>
                </nav>
              ) : (
                <nav className="flex items-center space-x-6">
                  <Link
                    to="/login"
                    className="px-4 py-2 text-sm font-medium text-zinc-400 hover:text-white hover:bg-white/5 transition-colors rounded"
                  >
                    Login
                  </Link>
                  <Link
                    to="/signup"
                    className="px-4 py-2 rounded text-sm font-medium text-white bg-[#2a3f2a]/80 hover:bg-[#2a3f2a] transition-colors backdrop-blur-md"
                  >
                    Sign Up
                  </Link>
                </nav>
              )}

              <div className="md:hidden flex items-center ml-4">
                <button className="p-2 rounded text-zinc-400 hover:text-white hover:bg-white/5 transition-colors">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {generateBreadcrumbs()}
    </div>
  );
};

export default Navigation;