import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from './Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { SUBSCRIPTION_TIERS } from './subscriptionUtils';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userSubscription, setUserSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();
        
        // Check subscription status
        if (userData?.subscriptionEndsAt && userData.subscriptionEndsAt.seconds * 1000 < Date.now()) {
          // Subscription expired
          await updateDoc(userDocRef, {
            subscriptionStatus: SUBSCRIPTION_TIERS.FREE,
            subscriptionId: null,
            subscriptionEndsAt: null
          });
          userData.subscriptionStatus = SUBSCRIPTION_TIERS.FREE;
        }
        
        setCurrentUser({ ...user, ...userData });
        setUserSubscription(userData?.subscriptionStatus || SUBSCRIPTION_TIERS.FREE);
      } else {
        setCurrentUser(null);
        setUserSubscription(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, userSubscription, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
