import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import { loadStripe } from '@stripe/stripe-js';
import { SUBSCRIPTION_TIERS, TIER_LIMITS, TIER_PRICES } from './subscriptionUtils';
import { auth } from './Firebase';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from './Firebase';

// Initialize Stripe outside of component
const stripePromise = loadStripe('pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z');

const Upgrade = () => {
  const { currentUser, userSubscription } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { reason } = location.state || {};

  const getLimitMessage = () => {
    if (!reason) return null;
    
    const freeLimit = TIER_LIMITS[SUBSCRIPTION_TIERS.FREE];
    return (
      <div className="relative mb-8">
        <div className="absolute inset-0 bg-gradient-to-r from-olive-dark/5 to-copper-dark/5 rounded-xl blur-lg" />
        <div className="relative backdrop-blur-sm bg-sand p-4 rounded-xl border border-olive-light">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-olive-dark" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-olive-dark">
                Free Plan Limit Reached
              </h3>
              <div className="mt-2 text-sm text-olive-dark">
                <p>
                  You've reached the {freeLimit} topic limit on the free plan. 
                  Upgrade to Premium for unlimited topics and advanced features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const createStripeCustomer = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      console.log('Creating customer for email:', currentUser.email);
      
      // First, check if user already exists in Firestore
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        // Initialize user document if it doesn't exist
        await setDoc(userRef, {
          email: currentUser.email,
          subscriptionStatus: SUBSCRIPTION_TIERS.FREE,
          createdAt: new Date(),
          lastUpdated: new Date()
        });
      }

      const response = await fetch('https://gol-server.onrender.com/stripe/create-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          email: currentUser.email,
          userId: currentUser.uid,
          metadata: {
            firebaseUID: currentUser.uid
          }
        })
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ detail: 'Failed to parse error response' }));
        console.error('Server error details:', errorData);
        throw new Error(errorData.detail || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Customer creation response:', data);

      if (!data.stripe_customer_id) {
        throw new Error('No customer ID returned from server');
      }

      // Update user document with Stripe customer ID
      await updateDoc(userRef, {
        stripeCustomerId: data.stripe_customer_id,
        lastUpdated: new Date()
      });

      return data.stripe_customer_id;
    } catch (error) {
      console.error('Full error details:', {
        message: error.message,
        stack: error.stack,
        name: error.name
      });
      throw new Error(`Customer creation failed: ${error.message}`);
    }
  };

  const handleUpgrade = async () => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      // Get or create Stripe customer
      let stripeCustomerId;
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      if (userData?.stripeCustomerId) {
        stripeCustomerId = userData.stripeCustomerId;
      } else {
        stripeCustomerId = await createStripeCustomer();
      }

      const token = await auth.currentUser.getIdToken();
      
      const response = await fetch('https://gol-server.onrender.com/stripe/createCheckoutSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          userId: currentUser.uid,
          stripeCustomerId: stripeCustomerId,
          priceId: TIER_PRICES[SUBSCRIPTION_TIERS.PREMIUM],
          successUrl: `${window.location.origin}/success`,
          cancelUrl: `${window.location.origin}/cancel`
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to create checkout session');
      }

      const { sessionId } = await response.json();
      
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw error;
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to start checkout process. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-sand py-12 px-4">
      <div className="max-w-4xl mx-auto">
        {getLimitMessage()}
        
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-olive-dark mb-4">
            Enhance Your Learning with StudyAI Premium
          </h1>
          <p className="text-xl text-olive-dark max-w-2xl mx-auto">
            Supercharge your study sessions with AI-powered learning tools and unlimited access
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden mx-auto max-w-2xl">
          <div className="p-8 border-b border-olive-light">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-2xl font-semibold text-olive-dark">Pro Plan</h2>
              <span className="bg-copper-light text-copper-dark px-3 py-1 rounded-full text-sm font-medium">
                Most Popular
              </span>
            </div>
            
            <div className="flex items-baseline mb-8">
              <span className="text-5xl font-bold text-olive-dark">$8</span>
              <span className="text-olive-dark ml-2">/month</span>
            </div>

            <ul className="space-y-4 mb-8">
              {[
                'Unlimited study topics and materials',
                'Advanced AI study guide generation', 
                'Smart practice quizzes and tests',
                'Personalized learning paths',
                'Interactive flashcards with spaced repetition',
                'Detailed learning analytics and progress tracking',
                'Study material export options',
                '24/7 priority support'
              ].map((feature, i) => (
                <li key={i} className="flex items-center text-olive-dark">
                  <svg className="w-5 h-5 text-copper-dark mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>

            <button
              onClick={handleUpgrade}
              className="w-full bg-olive-dark hover:bg-olive-light text-white py-4 px-8 rounded-xl font-semibold transition-colors duration-200 flex items-center justify-center"
            >
              Start Learning Smarter
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </button>
          </div>

          <div className="bg-sand px-8 py-6">
            <p className="text-olive-dark text-sm text-center">
              No risk - cancel anytime with our 30-day money-back guarantee. Start maximizing your study potential today!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;
