// ContentInput.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ContentInput = ({ loading, setLoading, setKeyTerms, setCurrentStep, setError, currentUser }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const [isDragOver, setIsDragOver] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type === 'application/pdf' || selectedFile.type === 'text/plain' || 
          selectedFile.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        setFile(selectedFile);
        setError(null);
      } else {
        setError('Please upload a PDF, TXT, or PPTX file.');
      }
    }
  };

  const handleSubmit = async () => {
    if (!text.trim() && !file) {
      setError('Please enter text or upload a file');
      return;
    }

    if (!currentUser) {
      setError('You must be logged in to process content');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      let content = text;
      
      if (file) {
        const fileContent = await readFileContent(file);
        content = content.trim() ? `${content}\n\n${fileContent}` : fileContent;
      }

      // Process content and extract key terms
      const response = await fetch('YOUR_API_ENDPOINT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });

      const data = await response.json();
      setKeyTerms(data.keyTerms);
      setCurrentStep(2);
    } catch (error) {
      console.error('Error processing content:', error);
      setError('Error processing content. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const readFileContent = async (file) => {
    // Existing file reading logic
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const handleDelete = () => {
    setFile(null);
    setText('');
    setError(null);
  };

  return (
    <div className="relative">
      <div className="bg-[#1a2f1a] p-8 rounded-3xl shadow-xl">
        <h2 className="text-3xl font-bold mb-8 text-white">Input Your Study Material</h2>
        
        <div 
          className={`relative transition-all duration-300 ${isDragOver ? 'scale-102' : ''}`}
          onDrop={(e) => {
            e.preventDefault();
            setIsDragOver(false);
            handleFileChange({ target: { files: e.dataTransfer.files } });
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragOver(true);
          }}
          onDragLeave={() => setIsDragOver(false)}
        >
          <div className="border-2 border-dashed border-[#2a3f2a] rounded-xl p-8">
            {/* Text Input */}
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Paste or type your study material here..."
              className="w-full h-40 p-4 mb-4 bg-[#2a3f2a] text-white rounded-lg resize-none focus:ring-2 focus:ring-[#4a5f4a] focus:outline-none"
              disabled={loading}
            />

            {/* File Upload */}
            <div className="text-center">
              <input
                type="file"
                onChange={handleFileChange}
                accept=".pdf,.txt,.pptx"
                className="hidden"
                id="fileInput"
                disabled={loading}
              />
              <label
                htmlFor="fileInput"
                className={`cursor-pointer block ${loading ? 'opacity-50' : ''}`}
              >
                <div className="text-[#8ba88b] mb-4">
                  {file ? (
                    <div className="flex items-center justify-center space-x-2">
                      <p>Selected file: {file.name}</p>
                      <button 
                        onClick={(e) => {
                          e.preventDefault();
                          setFile(null);
                        }}
                        className="text-red-400 hover:text-red-300"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <>
                      <p className="text-lg font-medium mb-2">Drag and drop a file or click to browse</p>
                      <p className="text-sm text-[#5a785a]">(Supported formats: PDF, TXT, PPTX)</p>
                    </>
                  )}
                </div>
              </label>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={loading || (!text.trim() && !file)}
            className={`
              w-full px-6 py-3 rounded-lg font-medium text-center
              ${loading || (!text.trim() && !file)
                ? 'bg-white/30 backdrop-blur-sm text-gray-400 cursor-not-allowed border border-white/20'
                : 'bg-white/70 backdrop-blur-sm text-olive-dark border border-white/50 shadow-lg hover:bg-white/90 hover:shadow-xl hover:translate-y-[-1px]'
              }
              transition-all duration-300
            `}
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                Processing...
              </span>
            ) : (
              'Process Content'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentInput;