export const SUBSCRIPTION_TIERS = {
  FREE: 'free',
  PREMIUM: 'premium'
};

export const TIER_LIMITS = {
  [SUBSCRIPTION_TIERS.FREE]: 2,
  [SUBSCRIPTION_TIERS.PREMIUM]: Infinity
};

export const TIER_PRICES = {
  [SUBSCRIPTION_TIERS.PREMIUM]: 'price_1Qj1xkLwpaCHfpZ1l8Qsdrjd'  // Make sure this matches the backend price ID
}; 