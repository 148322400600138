import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthProvider';

const SuccessPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Redirect to dashboard after 5 seconds
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-sand py-12 px-4 flex items-center justify-center">
      <div className="max-w-md w-full space-y-8 text-center">
        <div className="flex flex-col items-center">
          <svg
            className="h-16 w-16 text-olive-dark"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h2 className="mt-6 text-3xl font-bold text-olive-dark">
            Welcome to Premium!
          </h2>
          <p className="mt-4 text-lg text-olive-dark/80">
            Your subscription has been successfully activated. You now have access to all premium features.
          </p>
          <div className="mt-8 space-y-4">
            <button
              onClick={() => navigate('/dashboard')}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-olive-dark hover:bg-olive-dark/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-olive-light"
            >
              Go to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage; 