import React from 'react';
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-sand py-12 px-4 flex items-center justify-center">
      <div className="max-w-md w-full space-y-8 text-center">
        <div className="flex flex-col items-center">
          <svg
            className="h-16 w-16 text-copper-dark"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h2 className="mt-6 text-3xl font-bold text-olive-dark">
            Payment Cancelled
          </h2>
          <p className="mt-4 text-lg text-olive-dark/80">
            Your subscription payment was cancelled. No charges were made.
          </p>
          <div className="mt-8 space-y-4">
            <button
              onClick={() => navigate('/upgrade')}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-olive-dark hover:bg-olive-dark/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-olive-light"
            >
              Try Again
            </button>
            <button
              onClick={() => navigate('/dashboard')}
              className="w-full flex justify-center py-2 px-4 border border-olive-dark rounded-md shadow-sm text-sm font-medium text-olive-dark bg-transparent hover:bg-olive-dark/10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-olive-light"
            >
              Back to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPage; 