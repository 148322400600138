// TopicCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const TopicCard = ({ topic, onRequestDelete, compact = false }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/topics/${topic.id}`);
  };

  // Format the date
  const formattedDate = topic.createdAt ? 
    format(topic.createdAt.toDate(), 'MMM d, yyyy') : 
    'Date not available';

  return (
    <div 
      className={`relative group ${
        compact 
          ? 'p-4' 
          : 'p-6 bg-white/80 backdrop-blur-xl rounded-2xl border border-white/30 shadow-xl hover:shadow-2xl transition-all duration-300 cursor-pointer'
      }`}
      onClick={handleClick}
    >
      {/* Background gradient effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-olive-light/5 to-copper-light/5 rounded-2xl -z-10" />
      
      {/* Content */}
      <div className="space-y-4">
        <div className="flex justify-between items-start">
          <h3 className="text-xl font-semibold text-gray-900">
            {topic.name}
          </h3>
          
          {/* Delete button - always visible but styled differently based on hover */}
          {onRequestDelete && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onRequestDelete();
              }}
              className={`
                delete-button
                transition-all duration-300
                ${compact 
                  ? 'opacity-0 group-hover:opacity-100 text-red-500 hover:text-red-700' 
                  : 'opacity-0 group-hover:opacity-100 p-2 hover:bg-red-50 rounded-full'
                }
              `}
              aria-label="Delete topic"
            >
              <svg 
                className="w-5 h-5 text-red-500" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                />
              </svg>
            </button>
          )}
        </div>

        {!compact && (
          <>
            <p className="text-gray-600">
              {topic.description || 'No description available'}
            </p>
            <div className="flex justify-between items-center text-sm text-gray-500">
              <span>{topic.terms?.length || 0} terms</span>
              <span>{formattedDate}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TopicCard;
